import React from 'react';
import styled from 'styled-components';

// Components
import { Col } from '../global/col';
import { Row } from '../global/row';
import Container from '../global/container';

const ThreeTwoImageContainer = styled.div`
  position: relative;
  overflow: hidden;

  max-width: 660px;
  margin: 0 auto;

  // &:before {
  //   display: block;
  //   content: '';
  //   width: 100%;
  //   padding-top: 66.667%;
  // }
  // & > div {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  // }
`;

const Image = styled.img`
  padding: ${props => props.padding};

  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const JournalImage = ({
  image,
  padding,
  marginTop,
  srcWebp,
  srcSetWebp,
}) => {
  return (
    <Container marginTop={marginTop}>
      <Row justifyContent="center">
        <Col col={12}>
          <ThreeTwoImageContainer>
            <div>
              <Image srcSet={srcSetWebp} src={srcWebp} loading="lazy" />
            </div>
          </ThreeTwoImageContainer>
        </Col>
      </Row>
    </Container>
  );
};
