import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

// Components
import { Col } from '../global/col';
import { Row } from '../global/row';
import Container from '../global/container';

const Text = styled.div`
  text-align: left;

  padding: ${props => props.padding};

  max-width: 660px;
  margin: ${props => props.margin || '60px auto'};

  & a {
    position: relative;
    color: #934e2a;
  }

  & p {
    &:first-of-type {
      margin-top: 0;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  & h1,
  & h2 {
    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif !important;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  @media (max-width: 768px) {
    margin: ${props => props.mobileMargin || '0 auto'};
  }
`;

const LinkContainer = styled.div`
  padding: ${props => props.padding};

  max-width: 660px;
  margin: ${props => props.margin || ' 60px auto'};

  @media (max-width: 768px) {
    margin: ${props => props.mobileMargin || '0 auto'};
  }

  & a {
    line-height: 100%;

    letter-spacing: 0.04em;

    color: rgba(35, 35, 35, 0.5);

    font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
      'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
      sans-serif;

    &:hover {
      color: #934e2a;
    }
  }
`;

const BodyText = ({ text, padding, margin, mobileMargin, link }) => {
  return (
    <Container>
      <Row justifyContent="center">
        <Col col={12}>
          <Text
            padding={padding}
            margin={margin}
            mobileMargin={mobileMargin}
            dangerouslySetInnerHTML={{ __html: text.html }}
          />

          {link && (
            <LinkContainer
              padding={padding}
              margin={margin}
              mobileMargin={mobileMargin}
            >
              <Link to={link}>Shop The Collection</Link>
            </LinkContainer>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default BodyText;
