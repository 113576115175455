import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import { Link } from 'gatsby';

// Styles
import '../styles/plyr.css';

// Components
// import { VimeoThumbnail } from './vimeo-thumbnail';

// Utils
// import { ConditionalWrapper } from '../utils/conditional-wrapper';

const VideoBannerContainer = styled.div`
  position: relative;
  z-index: 10;

  & a {
    display: block;
    cursor: pointer;
  }

  & iframe {
    user-select: none;
    pointer-events: none;
  }
`;

export const VideoBanner = ({ content }) => {
  const [player, setPlayer] = useState(null);
  const [isPlayerReady, setIsPlayerReady] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const Plyr = require('plyr');

      setPlayer(
        new Plyr(`#video-banner${content.id}`, {
          autoplay: true,
          autopause: true,
          loop: { active: true },
          controls: ['mute'],
          hideControls: false,
          clickToPlay: false,
          playsinline: true,
          muted: true,
          volume: 1,
          vimeo: {
            autopause: false,
            // background: true,
            byline: false,
            controls: false,
          },
        })
      );
    }
  }, []);

  useEffect(() => {
    if (player !== null) {
      player.on('playing', () => {
        setIsPlayerReady(true);
      });
    }
  }, [player]);

  if (content.primary.video.embed_url !== null) {
    return (
      <VideoBannerContainer>
        {/* <ConditionalWrapper
          condition={content.primary.link.url}
          wrapper={children => (
            <Link to={content.primary.link.url}>{children}</Link>
          )}
        >
          {isPlayerReady === false && (
            <VimeoThumbnail
              embed_url={content.primary.video.embed_url}
              width={1920}
              height={1080}
            />
          )} */}
        <div
          className="plyr__video-embed"
          id={`video-banner${content.id}`}
          dangerouslySetInnerHTML={{
            __html: content.primary.video.html,
          }}
        />
        {/* </ConditionalWrapper> */}
      </VideoBannerContainer>
    );
  } else {
    return null;
  }
};
