import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { withPreview } from 'gatsby-source-prismic';

// Components
import { Col } from '../components/global/col';
import { Row } from '../components/global/row';
import Container from '../components/global/container';
import BodyText from '../components/text/body-text';
import BodyImageCaption from '../components/text/body-image-caption';
import BodyImage from '../components/images/body-image';
import { JournalImage } from '../components/images/journal-image';
import { VideoBanner } from '../components/video/video-banner';
import { VideoWithControls } from '../components/video/video-with-controls';

const Text = styled.div`
  max-width: 660px;
  margin: 5px auto 0 auto;

  font-size: 12px;
  line-height: 12px;
  color: rgba(51, 51, 51, 0.4);

  font-family: 'Garnett Regular', system, -apple-system, '.SFNSText-Regular',
    'San Francisco', 'Roboto', 'Segoe UI', 'Helvetica Neue', 'Lucida Grande',
    sans-serif;
`;

const Journal = ({ data }) => {
  const article = data.prismicJournalArticle.data.body.map((content, index) => {
    if (content.slice_type === 'text') {
      return (
        <BodyText
          text={content.primary.text}
          mobileMargin={'0 auto'}
          margin={index === 0 && '30px auto 60px auto'}
        />
      );
    }
    // if (content.slice_type === 'full_width_video') {
    //   return (
    // <HeroVideo
    //       width={content.primary.journal_video.width}
    //       height={content.primary.journal_video.height}
    //       thumbnail={content.primary.journal_video.thumbnail_url}
    //       video={content.primary.journal_video.embed_url}
    //       videoId={content.primary.journal_video.video_id}
    //     />
    //   );
    // }

    if (content.slice_type === 'image') {
      return (
        <React.Fragment>
          {content.primary.article_image.fluid !== null && (
            <JournalImage
              srcWebp={content.primary.article_image.fluid.srcWebp}
              srcSetWebp={content.primary.article_image.fluid.srcSetWebp}
            />
          )}
          {content.primary.caption && (
            <BodyImageCaption text={content.primary.caption} />
          )}
        </React.Fragment>
      );
    }

    if (content.slice_type === 'full_width_video') {
      return <VideoBanner content={content} key={content.id} />;
    }

    if (content.slice_type === 'video_with_controls') {
      return <VideoWithControls content={content} key={content.id} />;
    }
  });

  return (
    <>
      {data.prismicJournalArticle.data.image.fluid !== null && (
        <BodyImage
          srcWebp={data.prismicJournalArticle.data.image.fluid.srcWebp}
          srcSetWebp={data.prismicJournalArticle.data.image.fluid.srcSetWebp}
          alt={data.prismicJournalArticle.data.image.alt}
          position={'center'}
          marginTop={`60px`}
        />
      )}

      <BodyText
        text={data.prismicJournalArticle.data.title}
        margin={'60px auto 0 auto'}
      />

      <Container marginTop={`0`}>
        <Row justifyContent="center">
          <Col col={12}>
            <Text>
              Published:
              <time> {data.prismicJournalArticle.data.date}</time>
            </Text>
          </Col>
        </Row>
      </Container>

      <div>{article}</div>
    </>
  );
};

export default withPreview(Journal);

export const JournalQuery = graphql`
  query JournalQuery($uid: String!) {
    prismicJournalArticle(uid: { eq: $uid }) {
      data {
        body {
          ... on PrismicJournalArticleBodyImage {
            id
            slice_type
            primary {
              article_image {
                url
                alt
                fluid(maxWidth: 1000) {
                  srcWebp
                  srcSetWebp
                }
              }
              caption
            }
          }
          ... on PrismicJournalArticleBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicJournalArticleBodyFullWidthVideo {
            id
            slice_type
            primary {
              video {
                html
                width
                height
                embed_url
              }
              call_to_action_text {
                html
              }
              link
              button_text
            }
          }
          ... on PrismicJournalArticleBodyVideoWithControls {
            id
            slice_type
            primary {
              video {
                html
                height
                width
                embed_url
              }
            }
          }
        }
        image {
          url
          alt
          fluid(maxWidth: 1000) {
            srcWebp
            srcSetWebp
          }
        }
        title {
          html
        }
        date(formatString: "MMMM ‘YY")
      }
    }
  }
`;
