import React from 'react';
import styled from 'styled-components';

// Components
import { Col } from '../global/col';
import { Row } from '../global/row';
import Container from '../global/container';

const Text = styled.p`
  padding: ${props => props.padding};
  color: rgba(35, 35, 35, 0.7);

  // margin-bottom: 0;

  max-width: 660px;
  margin: 1em auto 0 auto;
`;

const BodyImageCaption = ({ text, padding }) => {
  return (
    <Container marginTop={'0'}>
      <Row justifyContent="center">
        <Col col={12}>
          <Text>{text}</Text>
        </Col>
      </Row>
    </Container>
  );
};

export default BodyImageCaption;
